// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

// mobx
import { observer } from 'mobx-react';

// fragments
import { fragments } from 'services/get-fragments';

// react-router

// semantic-ui
import { Header, Segment, Container, Image } from 'semantic-ui-react';
import CardDisplay from 'components/Home/CardDisplay';

const styles = {
	sideBySideContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		padding: '50px',
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	textContainer: {
		flex: 1,
		marginLeft: '180px',
	},
	imageContainer: {
		flex: 1,
		marginRight: '20px',
	},
};

export const HomePage = observer(() => (
	<>
		<Segment
			vertical
			style={{
				color: 'black',
				backgroundColor: '#F9F9FA',
				padding: '40px 0px',
				paddingTop: '0px',
				margin: '0px !important',
			}}
		>
			<div style={styles.sideBySideContainer}>
				<div style={styles.textContainer}>
					<h1
						style={{
							fontSize: '48px',
							fontWeight: '700',
							lineHeight: '56px',
							letterSpacing: '-1.5px',
							textAlign: 'left',
						}}
					>
						<span>
							Ship out commerce
							<br></br>
							solutions anytime,
							<br></br>
							anywhere
							<br></br>
						</span>
					</h1>
				</div>
				<div style={styles.imageContainer}>
					<Image centered size='medium' src='/custom-content/hero-banner.png' />
				</div>
			</div>
		</Segment>
		<Segment vertical style={{ padding: '40px 0px', margin: '0 !important' }}>
			<Container fluid text textAlign='justified'>
				<Header as='h1'>{fragments.Home.headerCard}</Header>
				<CardDisplay />
			</Container>
		</Segment>
	</>
));

export default HomePage;
