// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import {
	Grid,
	Header,
	Popup,
	Loader,
	Message,
	List,
	Divider,
	Input,
	Button,
	TextArea,
	Form,
} from 'semantic-ui-react';

import Chart from 'chart.js';
import { fetchUsage, mapUsageByDate } from 'services/api-catalog';
import { toTitleCase } from 'services/misc';

import { observer } from 'mobx-react';

import { store } from 'services/state';

import _ from 'lodash';
import { useState, useEffect } from 'react';

import ParticipantService from 'services/participant';

function loadUsage(usagePlan, canvasId) {
	fetchUsage(usagePlan.id)
		.then((result) => {
			const data = mapUsageByDate(result.data, 'used');
			const ctx = document.getElementById(canvasId);

			const oldDataString = JSON.stringify(_.get(usagePlan, 'usage.data', {}));
			const newDataString = JSON.stringify(data);

			if (oldDataString !== newDataString) {
				usagePlan.usage = { data };
			}

			const labels = data.map((d) =>
				new Date(d[0]).toLocaleDateString('en-US', {
					weekday: 'short',
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				})
			);
			const used = data.map((d) => d[1]);
			const remaining = data.map((d) => d[2]);
			const max = Math.max(...used, ...remaining);

			const chartConfig = {
				type: 'line',
				data: {
					labels,
					datasets: [
						{
							label:
								'Requests used' + (usagePlan.quota ? ` (per ${toTitleCase(usagePlan.quota.period)})` : ''),
							data: used,
							lineTension: 0,
							backgroundColor: '#00bfff',
							borderColor: '#00bfff',
							pointBackgroundColor: 'transparent',
							pointBorderColor: 'transparent',
							borderWidth: 2,
							pointRadius: 10,
							pointHoverRadius: 10,
						},
						{
							label:
								'Remaining requests' +
								(usagePlan.quota ? ` (per ${toTitleCase(usagePlan.quota.period)})` : ''),
							data: remaining,
							lineTension: 0,
							backgroundColor: 'transparent',
							borderColor: 'red',
							pointBackgroundColor: 'transparent',
							pointBorderColor: 'transparent',
							borderWidth: 2,
							pointRadius: 10,
							pointHoverRadius: 10,
						},
					],
				},
				options: {
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
									suggestedMax: max + max * 0.02,
								},
							},
						],
					},
				},
			};

			// eslint-disable-next-line no-new
			new Chart(ctx, chartConfig);
		})
		.catch((error) => {
			console.error(error);
			if (!usagePlan.usage) {
				usagePlan.usage = {};
			}

			usagePlan.usage.error = error;
		});
}

export default observer(() => {
	const [participantId, setParticipantId] = useState('');
	const [clientId, setClientId] = useState([]);
	const [clientIdError, setClientIdError] = useState('');
	const [mtlsCertificate, setMtlsCertificate] = useState('');
	const email = store.user && store.user.email;
	// Function to fetch participant data
	const fetchParticipantData = async () => {
		try {
			const data = await ParticipantService.getParticipantData();
			// Check if participant data is available
			const filteredData = data.filter((item) => item.UserId === email);
			filteredData.forEach((item) => {
				if (item.ParticipantId) {
					setParticipantId(item.ParticipantId);
				}
				if (item.ClientId) {
					setClientId((prevClientId) => [...prevClientId, item.ClientId]);
				}
				if (item.Certificate) {
					setMtlsCertificate(item.Certificate);
				}
			});
		} catch (error) {
			console.error('Failed to fetch participant data:', error);
			// Handle error if needed
		}
	};

	useEffect(() => {
		// Fetch participant data when the component mounts
		fetchParticipantData();
	}, []);
	// Function to handle changes in the fields
	const handleFieldChange = (e, fieldName, setValue, setError) => {
		const value = e.target.value;
		setValue(value);

		if (fieldName === 'Client ID') {
			// Validate Client ID (alphanumeric, no symbols)
			const isValidClientId = /^[a-zA-Z0-9]+$/.test(value);
			setError(isValidClientId ? '' : 'Client ID should be alphanumeric without symbols');
		}
	};

	// Function to handle adding a participant
	const handleAddParticipant = async () => {
		try {
			// Fetch data for the participant from the backend
			const participantData = {
				ParticipantId: participantId,
				UserId: email,
				// Add other necessary fields based on the backend requirements
			};
			await ParticipantService.createParticipantData(participantData);
		} catch (error) {
			console.error('Error fetching participant data:', error);
		}
	};

	// Function to handle adding a client ID
	const handleAddClient = async () => {
		try {
			// Create data for the client ID
			const clientData = {
				ClientId: clientId,
				UserId: email,
				// Add other necessary fields based on the backend requirements
			};

			// Use the created client data as needed
			await ParticipantService.createParticipantData(clientData);

			console.log('Successfully added Client ID:', clientId);
		} catch (error) {
			console.error('Error adding Client ID:', error);
		}
	};

	// Function to handle uploading MTLS certificate
	const handleUploadMTLSCertificate = async () => {
		try {
			// Create data for the client ID
			const certificateData = {
				Certificate: mtlsCertificate,
				UserId: email,
			};

			await ParticipantService.createParticipantData(certificateData);

			console.log('Successfully added certificate:', certificateData);
		} catch (error) {
			console.error('Error adding Client ID:', error);
		}
	};

	return (
		<Grid container>
			<Grid.Row>
				<Grid.Column style={{ paddingTop: '40px' }}>
					<Header size='medium'>API Key</Header>
					{store.apiKey ? (
						<code
							style={{
								background: 'black',
								border: '1px solid gray',
								padding: '7px 8px',
								color: 'lightgray',
								borderRadius: '5px',
							}}
						>
							{store.apiKey}
						</code>
					) : (
						// Note: this should be the same size as the text
						<Loader active inline size='tiny'>
							{store.apiKeyFetchFailed ? (
								<>
									Please wait a minute and try refreshing. If this doesn't work, please contact the admin for
									assistance.
								</>
							) : null}
						</Loader>
					)}
				</Grid.Column>
			</Grid.Row>
			<Divider />
			<Grid.Row>
				{store.usagePlans
					.filter((usagePlan) => usagePlan.subscribed && usagePlan.apis.length)
					.map((usagePlan, index) => {
						const canvasId = `api-usage-chart-container-${usagePlan.id}` + index;

						loadUsage(usagePlan, canvasId);

						return (
							<Grid.Column width={16} widescreen={8} key={usagePlan.id} style={{ marginBottom: '40px' }}>
								<Title apis={usagePlan.apis} />
								{usagePlan.throttle && (
									<Message info>
										<p>
											Requests limited to {usagePlan.throttle.rateLimit} per second, and{' '}
											{usagePlan.throttle.burstLimit} in a burst.
										</p>
									</Message>
								)}
								{!usagePlan.usage ? (
									<Loader active />
								) : usagePlan.error ? (
									<Message error content={usagePlan.error.toString()} />
								) : null}
								<canvas id={canvasId} />
							</Grid.Column>
						);
					})}
			</Grid.Row>

			<Grid.Row>
				<Grid.Column>
					<Header size='medium'>Participant ID</Header>
					<Input
						fluid
						placeholder='Participant ID'
						value={participantId}
						onChange={(e) => handleFieldChange(e, 'Participant ID', setParticipantId, setClientIdError)}
					/>
					<div>
						<Button primary onClick={handleAddParticipant}>
							Add Participant
						</Button>
					</div>
				</Grid.Column>
			</Grid.Row>
			<Divider />

			<Grid.Row>
				<Grid.Column>
					<Header size='medium'>Client ID</Header>
					<Input
						fluid
						placeholder='Client ID'
						data-testid='client-id-input'
						value={clientId}
						onChange={(e) => handleFieldChange(e, 'Client ID', setClientId, setClientIdError)}
						error={!!clientIdError}
					/>
					{clientIdError && <Message error content={clientIdError} />}
					<div>
						<Button primary onClick={handleAddClient} disabled={clientIdError}>
							Add Client ID
						</Button>
					</div>
				</Grid.Column>
			</Grid.Row>
			<Divider />

			<Grid.Row>
				<Grid.Column>
					<Header size='medium'>MTLS Certificate</Header>
					<Form>
						<Form.Field>
							<TextArea
								id='mtlsCertificate'
								placeholder='Paste your MTLS certificate here...'
								value={mtlsCertificate}
								onChange={(e) => handleFieldChange(e, 'MTLS Certiciate', setMtlsCertificate)}
							/>
						</Form.Field>
						<br />
						<Button onClick={handleUploadMTLSCertificate} primary>
							Upload MTLS Certificate
						</Button>
					</Form>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
});

const Title = ({ apis }) => {
	const firstApiName = apis[0].swagger.info.title;

	const apiList = (
		<List>
			{apis.reduce(
				(acc, api) => acc.concat(<List.Item key={api.id}>{api.swagger.info.title}</List.Item>),
				[]
			)}
		</List>
	);

	const extraApiCount = apis.length - 1;

	return (
		<Header size='medium'>
			Usage for{' '}
			{extraApiCount ? (
				<Popup
					trigger={
						<a style={{ cursor: 'pointer' }}>
							{firstApiName} and {extraApiCount} more...
						</a>
					}
					content={apiList}
					on={['hover', 'click']}
					position='right center'
				/>
			) : (
				firstApiName
			)}
		</Header>
	);
};
