import React from 'react';
import { Card, CardGroup } from 'semantic-ui-react';

function CardDisplay() {
	return (
		<>
			<CardGroup itemsPerRow={2} style={{ flex: 'space-around' }}>
				<Card raised>
					<Card.Content>
						<Card.Header>Account Management</Card.Header>
						<Card.Description>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
							cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</Card.Description>
					</Card.Content>
				</Card>
				<Card raised>
					<Card.Content>
						<Card.Header>Payment Initiation</Card.Header>
						<Card.Description>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
							cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</Card.Description>
					</Card.Content>
				</Card>
			</CardGroup>
		</>
	);
}

export default CardDisplay;
