import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api';

const ParticipantService = {
	getParticipantData: async () => {
		try {
			const apiGatewayclient = await getApiGatewayClient();
			console.log(apiGatewayclient);
			const response = await apiGatewayclient.get(`/participantId`);
			return response.data;
		} catch (error) {
			console.error('Failed to fetch participant dat:', error);
			throw error;
		}
	},

	createParticipantData: async (participantData) => {
		console.log(participantData);
		try {
			const apiGatewayClient = await getApiGatewayClient();
			const response = await apiGatewayClient.post('/participantId', {}, participantData, {});
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error('Failed to create participant data:', error);
			throw error;
		}
	},

	// Example usage:
	// Assuming participantData is an object containing the data you want to create
	createParticipant: async (participantData) => {
		try {
			const createdParticipantData = await ParticipantService.createParticipantData(participantData);
			console.log('Successfully created participant data:', createdParticipantData);
			return createdParticipantData;
		} catch (error) {
			console.error('Failed to create participant data:', error);
			throw error;
		}
	},
};

export default ParticipantService;
